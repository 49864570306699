/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS THEME CUSTOM STYLES
----------------------------------------
!! Copy this file to your project's
   sass root. Don't edit the version
   in node_modules.
----------------------------------------
Custom project SASS goes here.

i.e.
@include u-padding-right('05');
----------------------------------------
*/

@use 'uswds-core' as *;

.hrheader {
  font-weight: 600;
  font-size: medium;
}

.box-shadow-3 {
  box-shadow: inset 0 0 0 3px;
  &:hover {
    box-shadow: inset 0 0 0 3px;
  }
}

// Visually hide contents that will be visible only to screen readers
// Source: https://webaim.org/techniques/css/invisiblecontent/#:~:text=Absolutely%20positioning%20content%20off%2Dscreen
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

#authentication-modal .usa-modal__main {
  margin: 0;
}

// Override USWDS' disabled style
input:disabled,
textarea:disabled {
  color: #5e5e5e;
  border: none;
  background-color: #e6e6e6;
}

.usa-button.usa-button--unstyled:disabled {
  box-shadow: none;
}

input::placeholder,
textarea::placeholder {
  font-style: italic;
}

// Custom non-registrant background color
.bg-internal {
  @include u-bg('mint-5v');
}

.text-internal {
  @include u-text('mint-50v');
}

.usa-button--internal {
  @include u-bg('mint-40v');
  &:hover {
    @include u-bg('mint-60v');
  }
}

.usa-button--outline-secondary {
  box-shadow: inset 0 0 0 2px #d83933;
  color: #d83933;
  &:not(:disabled):not([aria-disabled='true']):hover {
    box-shadow: inset 0 0 0 2px #8b0a03;
    color: #8b0a03;
  }
}

// Added a new button type: Discard Changes
.usa-button--discard-changes,
.usa-button--discard-changes:visited {
  background-color: #e0e0e0;
  @include u-text('red-50');
  &:hover {
    @include u-bg('red-60');
    @include u-text('white');
  }
  &[aria-disabled='true'] {
    @include u-bg('gray-20');
    @include u-text('gray-70');
  }
}

// Override USWDS' disabled button design
// https://cornerstonenw.slack.com/archives/C04DT1RS6A3/p1689278726666059
.usa-button:disabled {
  @include u-text('gray-40');
  background-color: unset;
  box-shadow: inset 0 0 0 2px color('gray-30');
  &:hover {
    background-color: unset;
    @include u-text('gray-40');
  }
}

// .bg-accent-warm-lighter is not as light
// need a counter part to .bg-accent-cool-lighter
.bg-accent-warm-even-lighter {
  background-color: #f7eee5;
}

.usa-hint {
  display: inline-block;
  margin-top: 0.2rem;
  font-style: italic;
  word-break: break-all;
}

.word-break-all {
  word-break: break-all;
}

.word-break-word {
  word-break: break-word;
}

// To hide the un-scollable scrollbar on Chrome
.usa-modal-overlay {
  overflow: hidden;
}

.usa-nav__submenu-item a {
  text-decoration: none;
}

.usa-logo {
  a {
    color: white;
  }
}

.usa-checkbox__input--tile + [class*='__label'],
.usa-radio__input--tile + [class*='__label'] {
  display: flex;
  align-items: center;
  margin-top: 0;
  padding: 0.5rem 0.5rem 0.5rem 2.2rem;

  // The checkbox
  &::before {
    margin-top: 0;
    height: 1rem;
    width: 1rem;
  }
}

@mixin checkbox-recolor-container($color-lite, $color-dark) {
  .usa-checkbox__input--tile:checked + [class*='__label'] {
    background-color: $color-lite;
    border-color: $color-dark;

    // The checkbox
    &::before {
      background-color: $color-dark;
      box-shadow: 0 0 0 2px $color-dark;
    }
  }
}

.usa-checkbox--accent-warm {
  @include checkbox-recolor-container(#f2e4d4, #c05600);
}

.usa-checkbox--secondary {
  @include checkbox-recolor-container(#f8dfe2, #d83933);
}

.usa-checkbox--secondary-unchecked {
  &.usa-checkbox {
    background-color: transparent;
  }

  .usa-checkbox__input--tile + [class*='__label'] {
    background: transparent;
    border-color: #d83933;
    border-width: 3px;
    color: #d83933;
    font-weight: bold;

    // The checkbox
    &::before {
      background-color: transparent;
      box-shadow: 0 0 0 2px #d83933;
    }
  }
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.gap-x-105 {
  gap: 0 0.75rem;
}

// Status colors (radios and tags)
@mixin status-color($color-lite, $color-dark) {
  &.usa-tag {
    @include u-bg($color-dark);
  }
  &.status-text {
    @include u-text($color-dark);
  }

  .usa-radio__input--tile:checked + [class*='__label'] {
    @include u-bg($color-lite);
    @include u-border($color-dark);

    &::before {
      @include u-bg($color-dark);
      box-shadow:
        0 0 0 2px color($color-dark),
        inset 0 0 0 2px #fff;
    }
  }
}

.draft-status {
  @include status-color('gold-5v', 'gold-50v');
}

.tentative-status {
  @include status-color('red-5v', 'red-60v');
}

.scheduled-status {
  @include status-color('green-cool-5v', 'green-cool-50v');
}

.postponed-status {
  @include status-color('violet-5', 'violet-50');
}

.completed-status {
  @include status-color('cyan-5', 'cyan-50');
}

.finalized-status {
  @include status-color('gray-3', 'gray-50');
}

.cancelled-status {
  @include status-color('gray-20', 'gray-80');
}

// To counteract .usa-button.usa-button--hover, .usa-button:hover styling in `button-disabled.scss`
.usa-pagination__button.usa-button:hover {
  border-bottom: 1px solid;
}
.usa-pagination__button.usa-current:hover {
  border: none;
}

.mtg-success-accordion {
  .usa-accordion__button {
    font-weight: 500;
    @include u-bg('base-lighter');

    &:hover {
      @include u-bg('base-light' !important);
    }
  }
  .usa-accordion__content {
    @include u-border('base-lighter');
    @include u-padding-top(2);
    @include u-padding-bottom(1);
  }
}

.status-accordion {
  .usa-accordion__button {
    font-weight: 500;
  }
  .status-list li {
    max-width: none;
  }
}

.usa-breadcrumb {
  margin-top: -2.5rem;
  margin-bottom: 1rem;
}

.form-container.shadow {
  box-shadow:
    0 0 2.5rem rgba(0, 0, 0, 0.1),
    0 0.5rem 1rem rgba(0, 0, 0, 0.3);
}

.margin-top-0-important {
  margin-top: 0 !important;
}

.table_email_cell {
  @include u-padding-top(1 !important);
  @include u-padding-bottom(0 !important);
}

// Same width as mobile-lg
@media screen and (min-width: 30em) {
  /* source: https://stackoverflow.com/a/44647131 */
  .hrheader {
    display: flex;
    flex-direction: row;

    &:before,
    &:after {
      content: '';
      flex: 1 1;
      border-bottom: 1px solid;
      border-color: #9e9e9e;
      margin: auto;
    }

    &:before {
      margin-right: 1rem;
    }

    &:after {
      margin-left: 1rem;
    }
  }
}

@media screen and (min-width: 64em) {
  .usa-nav-container {
    &::after {
      content: none;
    }
  }

  .usa-nav__primary > .usa-nav__primary-item {
    font-size: 1rem;
  }

  .usa-header--basic {
    .usa-navbar,
    .usa-nav {
      width: auto !important;
    }
    .usa-nav {
      padding: 0 !important;
    }
  }

  .usa-nav__submenu {
    width: 11rem;
    background-color: #15396c;
  }

  .usa-logo {
    margin: 0;
  }

  .usa-alert--slim .usa-alert__body {
    padding-right: 1rem;
  }

  .usa-alert--slim.usa-alert--no-icon .usa-alert__body {
    margin-left: 0;
  }
}

.usa-alert .usa-alert__body {
  word-break: break-word;
}

@media screen and (max-width: 63.99em) {
  .usa-navbar {
    border: none;
  }
}

.maxhw-modal {
  max-height: calc(100vh - 1rem);
}

// Custom <input type="file">
// When the default/USWDS display of <input type="file"> is not desired, offscreen_file_input and offscreen_file_label
// classes can be applied an <input> and its immediate sibling <label>, respectively to handle all styles via the
// <label> element.
//
// This mimics the strategy used by USWDS to handle checkboxes entirely via labels: .usa-checkbox__input styles
// https://github.com/uswds/uswds/blob/4a1f9fbbe640876dbc101ff6b46ef8231bda971d/packages/usa-checkbox/src/styles/_usa-checkbox.scss
//
// Example HTML:
//   <input type="file" className="offscreen_file_input" id="unique-id" disabled={is_disabled} />
//   <label for="unique-id" className="offscreen_file_label usa-button" aria-disabled={is_disabled}>Select File</label>
.offscreen_file_input {
  // This puts the input element offscreen.
  @include sr-only();

  &:focus + .offscreen_file_label {
    @include focus-outline(null, null, null, 0.5);
  }

  &:disabled + .offscreen_file_label,
  &[aria-disabled='true'] + .offscreen_file_label {
    cursor: not-allowed;
    // To allow for different label styles (e.g. filled color button vs. outline button), colors are not handled here.
    // They should be applied per-label. It also possible to take advantage of USWDS styles with something like:
    //   <label className="offscreen_file_label usa-button" aria-disabled={is_disabled}>
  }
}

// React tabs, putting it in custom-styles to use USWDS vars
.react-tabs__tab {
  @include u-padding-x(2 !important);
  @include u-padding-y('105' !important);
  @include u-radius(0 !important);

  &:not(.react-tabs__tab--selected):hover {
    @include u-bg('base-lightest' !important);
  }

  &[tabindex]:focus {
    @include focus-outline(null, null, null, 0.5);
  }
}

.react-tabs__tab--selected {
  @include u-border-top('05', 'primary', !important);
  @include u-color('primary' !important);
  font-weight: 600;
}
