@forward 'uswds-theme';
@forward 'uswds';
@forward 'uswds-custom-styles';
@use '../../../node_modules/react-tabs/style/react-tabs.scss';
@import 'react-tooltip/dist/react-tooltip.css';

#root {
  position: relative;
  min-height: 100vh;
}

.react-tooltip {
  z-index: 2; // To compensate for the positioning in TableUI.tsx
  max-width: 90vw;
}
